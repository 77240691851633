<template>
  <b-modal
    id="practice-request-modal"
    :title="$t('actions.make-request')"
    :ok-title="$t('actions.request')"
    :ok-disabled="state.isProcessing"
    :cancel-title="$t('labels.cancel')"
    :cancel-disabled="state.isProcessing"
    @ok="makeRequest"
  >
    <p>
      "{{ $t('student-lab-module.hi-teacher') }} 😊 {{ $t('student-lab-module.more-practice') }} "
    </p>
    <p>
      {{ $t('student-lab-module.understand-better') }}🌟🌟
    </p>
    <hr>
    <feather-icon icon="InfoIcon"
                  class="text-warning"
    /> {{ $t('student-lab-module.after-request') }}🌟🌟
  </b-modal>
</template>

<script setup>
import { BModal } from 'bootstrap-vue'
import { getCurrentInstance, ref, inject } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

const instance = getCurrentInstance().proxy.$root
const self = getUserData()
const state = ref({
  isProcessing: false,
})

const injectedClassRoomId = inject('injectedClassRoomId', null)
const props = defineProps({
  lessonId: {
    type: Number,
    required: true,
  },
})

const makeRequest = bvModal => {
  bvModal.preventDefault()
  state.isProcessing = true
  const params = {
    classId: injectedClassRoomId || instance.$route.params.classId,
    lessonId: props.lessonId,
    studentId: self.id,
  }

  useJwt.requestPracticeLessonsForClass(params).then(response => {
    instance.showSuccessMessage(response)
  }).catch(error => {
    instance.showErrorMessage(error)
  }).finally(() => {
    instance.$bvModal.hide('practice-request-modal')
    state.isProcessing = false
  })
}
</script>

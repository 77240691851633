<template>
  <div>

    <b-row v-if="!$store.getters['app/isNarrowMode']"
           class="lesson-group-large"
    >
      <!-- Lesson Group List -->
      <b-col
        md="6"
        sm="12"
        class="mb-1"
      >
        <b-card
          class="mb-0 p-2"
          no-body
        >
          <div v-if="lessonGroups">
            <div class="d-flex align-items-baseline">
              <slot name="back-button" />
              <h5 class="font-weight-bold mb-2">
                {{ $t("labels.lesson-groups") }}
              </h5>
            </div>

            <div
              v-for="(lessons, group, index) in lessonGroups[0]"
              :key="group"
              class="group"
            >

              <b-card
                border-variant="secondary"
                no-body
                class="mb-0 p-2 mb-1"
                :class="{ selected: group === selectedGroup }"
                :set="overallLessonProgress = lessonProgressList.find(item => item.lesson_group_id === lessons[0].lesson_group_id)"
                @click="selectGroup(group)"
              >
                <span v-if="aboveStudent"
                      v-b-tooltip.hover="{
                        title: countStudentOccurrence(overallLessonProgress?.users),
                        html: true,
                      }"
                      style="position: absolute;right: 0;margin-right: 10px;z-index: 1000;"
                      class="badge badge-light-primary"
                >
                  <span v-if="overallLessonProgress">
                    {{ overallLessonProgress?.lesson_done }} / {{ overallLessonProgress?.lesson_given }}
                  </span>
                </span>
                <span
                  v-else
                  style="position: absolute;right: 0;margin-right: 10px;"
                  class="badge badge-light-primary"
                >
                  {{ lessons.filter(item => !!item.completed_at).length }} / {{ lessons.length }}
                </span>
                <div class="row">
                  <b-button
                    v-b-tooltip.hover="'Visualize'"
                    variant="flat-primary"
                    size="sm"
                    @click="showLessonVisualizationModal(lessons)"
                  >
                    <feather-icon
                      icon="BarChartIcon"
                    />
                  </b-button>
                  <h4 class="col-1 pr-0">
                    {{ index + 1 }}
                  </h4>

                  <div class="col-10 d-flex align-items-start justify-content-between">
                    <h4>
                      {{ group }}
                    </h4>

                  </div>
                </div>
                <b-progress
                  v-if="aboveStudent"
                  :value="overallLessonProgress?.progress"
                  max="100"
                  striped
                  class="progress-bar-success mt-1"
                  variant="success"
                />
                <b-progress
                  v-else
                  :value="lessons.filter(item => !!item.completed_at).length/lessons.length * 100"
                  max="100"
                  striped
                  class="progress-bar-success mt-1"
                  variant="success"
                />
              </b-card>

            </div>
          </div>

          <p v-else>
            {{ $t("labels.no-lessons-yet") }}
          </p>
        </b-card>
      </b-col>

      <!-- Lesson List -->
      <b-col
        md="6"
        sm="12"
      >
        <b-card class="mb-0 p-2">
          <div v-if="lessonGroups.length && selectedGroup">
            <h5 class="mb-2">
              {{ $t("labels.lessons-in") }} <strong>{{ selectedGroup }}</strong>
            </h5>
            <div v-if="lessonGroups[0][selectedGroup].length">
              <b-tabs v-model="lessonTypeTab">
                <b-tab>
                  <template #title>
                    {{ $t('main-lessons') }}
                    <b-badge variant="dark"
                             class="ml-1 text-white badge-pill "
                    >
                      {{ orderLesson(lessonGroups[0][selectedGroup]).length }}
                    </b-badge>
                  </template>
                  <template v-for="(lesson, index) in orderLesson(lessonGroups[0][selectedGroup])">
                    <div
                      v-if="!(lesson.is_locked && isAStudent && !isClassOwner)"
                      :key="index"
                      class="group"
                    >
                      <span
                        v-if="isATeacher"
                        style="position: absolute;right: 45px;z-index: 9;"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          class="text-warning"
                          @click="lessonIdToShowDetail = lesson.lesson_id"
                        />
                      </span>
                      <b-card
                        border-variant="secondary"
                        no-body
                        class="mb-0 p-2 mb-1"
                        @click="
                          () => {
                            if (lesson.is_unlock_request_sent) {
                              return
                            }
                            if (lesson.is_locked && isAStudent && !isClassOwner) {
                              showLockedMessage(lesson)
                              return
                            }
                            if(($route.query.student_id || lesson.completed_at) && hideResult) {
                              $router.push({
                                name: `${self.usertype}-problems`,
                                params: { ...$route.params,
                                  classId: classId || $route.params.classId,
                                  id: lesson.lesson_id
                                },
                                query: {
                                  event_id: lesson.event_id,
                                },
                              });
                            } else if ($route.query.student_id || lesson.completed_at) {
                              $router.push({
                                name: `${self.usertype}-course-result`,
                                params: {
                                  classId: classId || $route.params.classId,
                                  id: courseId,
                                  type: $route.params.type,
                                },
                                query: {
                                  lesson_id: lesson.lesson_id,
                                  student_id: $route.query.student_id,
                                },
                              });
                            } else if (lesson.event_type === 'conversational') {
                              showLessonVisualizationModalForSpecificLesson(lesson)
                            } else {
                              if (selectedCourseId) {
                                $emit('showLesson', lesson)
                              } else {
                                $router.push({
                                  name: `${self.usertype}-lesson`,
                                  params: { ...$route.params, id: lesson.lesson_id},
                                  query: {
                                    event_id: lesson.event_id,
                                  },
                                });
                              }

                            }
                          }
                        "
                      >
                        <div v-if="lesson.event_type === 'conversational'"
                             v-b-tooltip.hover="'The is lesson is conversational mode'"
                             style="position: absolute; bottom: 0; right: 0; margin-right: 10px;"
                        >
                          <img
                            src="/conversational.png"
                            width="40px"
                            height="40px"
                          >
                        </div>
                        <!-- Completed Icon -->
                        <img
                          v-if="lesson.completed_at"
                          :src="require(`@/assets/images/${lessonCompletedBadge}`)"
                          class="lesson-completed-badge"
                        >
                        <feather-icon
                          v-else-if="lesson.is_locked && isAStudent && !isClassOwner"
                          icon="LockIcon"
                          class="lesson-completed-badge"
                        />
                        <!-- Lesson Details -->
                        <b-row class="align-items-start justify-content-left">
                          <b-col
                            md="3"
                            sm="12"
                            class="mb-1 mb-md-0"
                          >
                            <!-- Lesson Image -->
                            <avatar
                              :image="lesson.image"
                              :rounded="true"
                              :default-icon="'book'"
                              class="lesson-image"
                            />
                          </b-col>

                          <!-- Details -->
                          <b-col
                            :md="9"
                            :sm="12"
                            class="pt-1 pl-2 detail-responsive"
                          >
                            <!-- Lesson Details -->
                            <div class="lesson-details">
                              {{ selectedGroup }}
                              <h4>{{ lesson.name }}</h4>

                              <h6
                                v-if="lesson.start_time"
                                class="font-italic"
                              >
                                {{ $t('assigned-at') }}: {{ formatDay(lesson.start_time) }}
                              </h6>
                              <h6
                                v-if="lesson.started_at"
                                class="font-italic"
                              >
                                {{ $t('started') }} {{ formatDate(lesson.started_at) }}
                                <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                              </h6>
                              <h6
                                v-if="lesson.completed_at"
                                class="font-italic text-success"
                              >
                                <span v-if="lesson.user_id == self.id">
                                  <b>{{ $t('you') }}</b>
                                </span>
                                <span v-else>
                                  <b>{{ lesson.firstname }}</b>
                                </span>
                                {{ $t('completed') }} {{ formatDate(lesson.completed_at) }}
                                <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                              </h6>
                            </div>

                            <!-- Actions -->
                            <b-col
                              class="d-flex
                              justify-content-between
                              align-items-baseline
                              flex-wrap"
                            >
                              <i
                                v-if="lesson.is_unlock_request_sent"
                                class="text-danger"
                              >
                                {{ $t('student-lab-modul.unlock-request-sent') }}
                              </i>

                              <div>
                                <template
                                  v-if="lesson.lesson_page && !lesson.hide_ebook"
                                >
                                  <!-- Live Link -->
                                  <b-badge
                                    v-if="!hideEbook"
                                    pill
                                    variant="success"
                                    class="mt-1 mr-1"
                                  >
                                    <span
                                      @click.stop="()=>{selectedLessonIdForEBook = lesson; selectedEventId=lesson.event_id}"
                                    >{{ $t("student-lab-module.e-book") }}
                                    </span>
                                  </b-badge>
                                  <b-badge
                                    v-if="lesson.live_link"
                                    pill
                                    variant="primary"
                                    class="mr-1 mt-1"
                                    @click.stop
                                  >
                                    <a :href="lesson.live_link"
                                       target="_blank"
                                    >
                                      {{ $t('student-lab-module.page-link') }}
                                    </a>
                                  </b-badge>

                                  <!-- Essay -->
                                </template>

                                <!-- Notes -->
                                <b-badge
                                  v-if="!hideNote"
                                  pill
                                  variant="warning"
                                  class="mr-1 mt-1"
                                >
                                  <a @click.stop="$emit('showNotes', lesson.lesson_id)">{{
                                    $t("student-lab-module.notes")
                                  }}</a>
                                </b-badge>

                                <!-- PDF -->
                                <b-badge
                                  v-if="lesson.file && !hidePdf"
                                  pill
                                  variant="danger"
                                  class="mr-1 mt-1"
                                >
                                  <a @click.stop="openPdf(lesson.file)">
                                    {{ $t("labels.pdf") }}
                                  </a>
                                </b-badge>
                                <b-badge
                                  v-if="lesson.has_multi_practice && lesson.completed_at"
                                  pill
                                  variant="info"
                                  class="mr-1 mt-1"
                                >
                                  <a
                                    @click.stop="
                                      $router.push({
                                        name: `${self.usertype}-lesson`,
                                        params: { id: lesson.lesson_id, classId },
                                        query: { type:'practice', event_id: lesson.event_id}
                                      })
                                    "
                                  >
                                    {{ $t("labels.practice_again") }}
                                  </a>
                                </b-badge>
                                <b-badge
                                  v-else-if="isAStudent && !hideMakeRequest"
                                  pill
                                  variant="primary"
                                  class="mr-1 mt-1"
                                  @click.stop="showPracticeRequestModal(lesson.lesson_id)"
                                >
                                  {{ $t('student-lab-module.make-request') }}
                                </b-badge>
                              </div>
                            </b-col>

                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </template>
                </b-tab>
                <b-tab
                  v-show="followUPlesson(lessonGroups[0][selectedGroup]).length > 0"
                >
                  <template #title>
                    {{ $t('follow-up-lessons') }}
                    <b-badge variant="dark"
                             class="ml-1 text-white badge-pill"
                    >
                      {{ followUPlesson(lessonGroups[0][selectedGroup]).length }}
                    </b-badge>
                  </template>
                  <template v-for="(lesson, index) in followUPlesson(lessonGroups[0][selectedGroup])">
                    <div
                      v-if="!(lesson.is_locked && isAStudent && !isClassOwner)"
                      :key="index"
                      class="group"
                    >
                      <span
                        v-if="isATeacher"
                        style="position: absolute;right: 45px;z-index: 9;"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          class="text-warning"
                          @click="lessonIdToShowDetail = lesson.lesson_id"
                        />
                      </span>
                      <b-card
                        border-variant="secondary"
                        no-body
                        class="mb-0 p-2 mb-1"
                        @click="
                          () => {
                            if (lesson.is_unlock_request_sent) {
                              return
                            }
                            if (lesson.is_locked && isAStudent && !isClassOwner) {
                              showLockedMessage(lesson)
                              return
                            }
                            if(($route.query.student_id || lesson.completed_at) && hideResult) {
                              $router.push({
                                name: `${self.usertype}-problems`,
                                params: { ...$route.params,
                                  classId: classId || $route.params.classId,
                                  id: lesson.lesson_id
                                },
                                query: {
                                  event_id: lesson.event_id,
                                },
                              });
                            } else if ($route.query.student_id || lesson.completed_at) {
                              $router.push({
                                name: `${self.usertype}-course-result`,
                                params: {
                                  classId: classId ||$route.params.classId,
                                  id: courseId,
                                  type: $route.params.type,
                                },
                                query: {
                                  lesson_id: lesson.lesson_id,
                                  student_id: $route.query.student_id,
                                },
                              });
                            } else if (lesson.event_type === 'conversational') {
                              showLessonVisualizationModalForSpecificLesson(lesson)
                            } else {
                              if (selectedCourseId) {
                                $emit('showLesson', lesson, 'follow_up')
                              } else {
                                $router.push({
                                  name: `${self.usertype}-lesson`,
                                  params: { ...$route.params, id: lesson.lesson_id},
                                  query: {
                                    event_id: lesson.event_id,
                                    mode: 'follow_up'
                                  },
                                });
                              }
                            }
                          }
                        "
                      >
                        <div v-if="lesson.event_type === 'conversational'"
                             v-b-tooltip.hover="'The is lesson is conversational mode'"
                             style="position: absolute; bottom: 0; right: 0; margin-right: 10px;"
                        >
                          <img
                            src="/conversational.png"
                            width="40px"
                            height="40px"
                          >
                        </div>
                        <!-- Completed Icon -->
                        <img
                          v-if="lesson.completed_at"
                          :src="require(`@/assets/images/${lessonCompletedBadge}`)"
                          class="lesson-completed-badge"
                        >
                        <feather-icon
                          v-else-if="lesson.is_locked && isAStudent && !isClassOwner"
                          icon="LockIcon"
                          class="lesson-completed-badge"
                        />
                        <!-- Lesson Details -->
                        <b-row class="align-items-start justify-content-left">
                          <b-col
                            sm="12"
                            md="3"
                            class="mb-1 mb-md-0"
                          >
                            <!-- Lesson Image -->
                            <avatar
                              :image="lesson.image"
                              :rounded="true"
                              :default-icon="'book'"
                              class="lesson-image"
                            />
                          </b-col>

                          <!-- Details -->
                          <b-col
                            md="9"
                            sm="12"
                            class="pt-1 pl-2 detail-responsive"
                          >
                            <!-- Lesson Details -->
                            <div class="lesson-details">
                              {{ selectedGroup }}
                              <h4>{{ lesson.name }}</h4>

                              <h6
                                v-if="lesson.start_time"
                                class="font-italic"
                              >
                                {{ $t('assigned-at') }}: {{ formatDay(lesson.start_time) }}
                              </h6>
                              <h6
                                v-if="lesson.started_at"
                                class="font-italic"
                              >
                                {{ $t('started') }} {{ formatDate(lesson.started_at) }} <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                              </h6>
                              <h6
                                v-if="lesson.completed_at"
                                class="font-italic text-success"
                              >
                                <span v-if="lesson.user_id == self.id">
                                  <b>{{ $t('you') }}</b>
                                </span>
                                <span v-else>
                                  <b>{{ lesson.firstname }}</b>
                                </span>
                                {{ $t('completed') }} {{ formatDate(lesson.completed_at) }}
                              </h6>
                            </div>

                            <!-- Actions -->
                            <div
                              class="d-flex
                              justify-content-between
                              align-items-baseline
                              flex-wrap"
                            >
                              <i
                                v-if="lesson.is_unlock_request_sent"
                                class="text-danger"
                              >
                                {{ $t('student-lab-modul.unlock-request-sent') }}
                              </i>

                              <div>
                                <template
                                  v-if="lesson.lesson_page && !lesson.hide_ebook"
                                >
                                  <!-- Live Link -->
                                  <b-badge
                                    v-if="!hideEbook"
                                    pill
                                    variant="success"
                                    class="mt-1 mr-1"
                                  >
                                    <span
                                      @click.stop="()=>{selectedLessonIdForEBook = lesson; selectedEventId=lesson.event_id}"
                                    >{{ $t("student-lab-module.e-book") }}
                                    </span>
                                  </b-badge>
                                  <b-badge
                                    v-if="lesson.live_link"
                                    pill
                                    variant="primary"
                                    class="mr-1 mt-1"
                                    @click.stop
                                  >
                                    <a :href="lesson.live_link"
                                       target="_blank"
                                    >
                                      {{ $t('student-lab-module.page-link') }}
                                    </a>
                                  </b-badge>
                                </template>

                                <!-- Notes -->
                                <b-badge
                                  v-if="!hideNote"
                                  pill
                                  variant="warning"
                                  class="mr-1 mt-1"
                                >
                                  <a @click.stop="$emit('showNotes', lesson.lesson_id)">{{
                                    $t("student-lab-module.notes")
                                  }}</a>
                                </b-badge>

                                <!-- PDF -->
                                <b-badge
                                  v-if="lesson.file && !hidePdf"
                                  pill
                                  variant="danger"
                                  class="mr-1 mt-1"
                                >
                                  <a @click.stop="openPdf(lesson.file)">
                                    {{ $t("labels.pdf") }}
                                  </a>
                                </b-badge>
                                <b-badge
                                  v-if="lesson.has_multi_practice && lesson.completed_at"
                                  pill
                                  variant="info"
                                  class="mr-1 mt-1"
                                >
                                  <a
                                    @click.stop="
                                      $router.push({
                                        name: `${self.usertype}-lesson`,
                                        params: { id: lesson.lesson_id, classId: classId },
                                        query: { type:'practice', event_id: lesson.event_id, mode: 'follow_up'}
                                      })
                                    "
                                  >
                                    {{ $t("labels.practice_again") }}
                                  </a>
                                </b-badge>
                                <b-badge
                                  v-else-if="isAStudent && !hideMakeRequest"
                                  pill
                                  variant="primary"
                                  class="mr-1 mt-1"
                                  @click.stop="showPracticeRequestModal(lesson.lesson_id)"
                                >
                                  {{ $t('student-lab-module.make-request') }}
                                </b-badge>
                              </div>
                            </div></b-col>

                        </b-row>
                      </b-card>
                    </div>
                  </template>
                </b-tab>
              </b-tabs>

            </div>
            <p v-else>
              {{ $t("labels.no-lessons-yet") }}
            </p>

            <pdf-viewer
              ref="pdfViewer"
              :pdf="activePdf"
              :toggle="showPdf"
              @pdfModalClosed="showPdf = false"
            />
          </div>
          <p
            v-else
            class="m-0"
          >
            <a href="#"
               @click.stop="openCalendar"
            >
              {{ $t("messages.select-lesson-group") }}
            </a>
          </p>
        </b-card>
        <ebook-selector
          v-if="selectedLessonIdForEBook"
          :lesson="selectedLessonIdForEBook"
          :event-id="selectedEventId"
          @close="selectedLessonIdForEBook = null"
        />
      </b-col>
    </b-row>
    <!-- mobile view -->
    <b-row v-else
           class="lesson-group-mobile"
    >
      <b-col>
        <div v-if="lessonGroups">
          <div
            v-for="(lessons, group, index) in lessonGroups[0]"
            :key="group+index"
            class="group"
          >
            <b-card
              class="mb-1"
              :set="overallLessonProgress = lessonProgressList.find(item => item.lesson_group_id === lessons[0].lesson_group_id)"
            >
              <div
                :ref="`lesson-group-collapse-${lessons[0].lesson_group_id}-${index}`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle="`lesson-group-collapse-${lessons[0].lesson_group_id}-${index}`"
                class="lesson-group-name"
                style="position:relative"
              >
                <div class="d-flex">
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="18"
                    class="text-body align-middle m-0 p-0 handle mr-0"
                  />
                  <span style="width: 72%; margin-left: 5px;"> {{ group }} </span>
                  <span v-if="aboveStudent"
                        v-b-tooltip.hover="{
                          title: countStudentOccurrence(overallLessonProgress?.users),
                          html: true,
                        }"
                        style="position: absolute;right: 0; top:0px; z-index: 1000;"
                        class="badge badge-light-primary"
                  >
                    <span v-if="overallLessonProgress">
                      {{ overallLessonProgress?.lesson_done }} / {{ overallLessonProgress?.lesson_given }}
                    </span>
                  </span>
                  <span
                    v-else
                    style="position: absolute; right: 0; top:0px; z-index: 1000;"
                    class="badge badge-light-primary"
                  >
                    {{ lessons.filter(item => !!item.completed_at).length }} / {{ lessons.length }}
                  </span>
                </div>
                <b-progress
                  v-if="aboveStudent"
                  :value="overallLessonProgress?.progress"
                  max="100"
                  striped
                  class="progress-bar-success"
                  variant="success"
                />
                <b-progress
                  v-else
                  :value="lessons.filter(item => !!item.completed_at).length/lessons.length * 100"
                  max="100"
                  striped
                  class="progress-bar-success"
                  variant="success"
                />
              </div>
              <b-collapse
                :id="`lesson-group-collapse-${lessons[0].lesson_group_id}-${index}`"
                class="lesson-group-collapse border-top"
              >
                <!-- {{ lessons }} -->
                <b-tabs v-model="lessonTypeTab"
                        class="lesson-group-tab"
                >
                  <b-tab>
                    <template #title>
                      {{ $t('main-lessons') }}
                      <b-badge variant="dark"
                               class="text-white badge-pill"
                      >
                        {{ orderLesson(lessonGroups[0][group]).length }}
                      </b-badge>
                    </template>
                    <b-list-group
                      v-for="lesson,lIndex in orderLesson(lessonGroups[0][group])"
                      :key="lesson.lesson_id+lIndex"
                    >
                      <b-list-group-item
                        v-if="!(lesson.is_locked && isAStudent && !isClassOwner)"
                        :key="index"
                        class="group mb-1"
                      >
                        <span
                          v-if="isATeacher"
                          style="position: absolute;right: 2px; top:-2px; z-index: 9;"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="text-warning"
                            @click="lessonIdToShowDetail = lesson.lesson_id"
                          />
                        </span>
                        <div
                          border-variant="secondary"
                          no-body
                          class="mb-0 mb-1"
                          @click="
                            () => {
                              if (lesson.is_unlock_request_sent) {
                                return
                              }
                              if (lesson.is_locked && isAStudent && !isClassOwner) {
                                showLockedMessage(lesson)
                                return
                              }
                              if(($route.query.student_id || lesson.completed_at) && hideResult) {
                                $router.push({
                                  name: `${self.usertype}-problems`,
                                  params: { ...$route.params,
                                    classId: classId || $route.params.classId,
                                    id: lesson.lesson_id
                                  },
                                  query: {
                                    event_id: lesson.event_id,
                                  },
                                });
                              } else if ($route.query.student_id || lesson.completed_at) {
                                $router.push({
                                  name: `${self.usertype}-course-result`,
                                  params: {
                                    classId: classId ||$route.params.classId,
                                    id: courseId,
                                    type: $route.params.type,
                                  },
                                  query: {
                                    lesson_id: lesson.lesson_id,
                                    student_id: $route.query.student_id,
                                  },
                                });
                              } else if (lesson.event_type === 'conversational') {
                                showLessonVisualizationModalForSpecificLesson(lesson)
                              } else {
                                if (selectedCourseId) {
                                  $emit('showLesson', lesson)
                                } else {
                                  $router.push({
                                    name: `${self.usertype}-lesson`,
                                    params: { ...$route.params, id: lesson.lesson_id},
                                    query: {
                                      event_id: lesson.event_id,
                                    },
                                  });
                                }
                              }
                            }
                          "
                        >
                          <div v-if="lesson.event_type === 'conversational'"
                               v-b-tooltip.hover="'The is lesson is conversational mode'"
                               style="position: absolute; bottom: 0; right: 0; margin-right: 10px;"
                          >
                            <img
                              src="/conversational.png"
                              width="40px"
                              height="40px"
                            >
                          </div>
                          <!-- Completed Icon -->
                          <img
                            v-if="lesson.completed_at"
                            :src="require(`@/assets/images/${lessonCompletedBadge}`)"
                            class="lesson-completed-badge"
                          >
                          <feather-icon
                            v-else-if="lesson.is_locked && isAStudent && !isClassOwner"
                            icon="LockIcon"
                            class="lesson-completed-badge"
                          />
                          <!-- Lesson Details -->
                          <b-row class="align-items-start justify-content-left">
                            <b-col
                              class="pr-0 mb-md-0 col-3"
                            >
                              <!-- Lesson Image -->
                              <avatar
                                :image="lesson.image"
                                :rounded="true"
                                :default-icon="'book'"
                                class="lesson-image"
                              />
                            </b-col>

                            <!-- Details -->
                            <b-col
                              class="pl-1 detail-responsive col-9"
                            >
                              <!-- Lesson Details -->
                              <div class="lesson-details">
                                {{ group }}
                                <h4>{{ lesson.name }}</h4>

                                <h6
                                  v-if="lesson.start_time"
                                  class="font-italic"
                                >
                                  {{ $t('assigned-at') }}: {{ formatDay(lesson.start_time) }}
                                </h6>
                                <h6
                                  v-if="lesson.started_at"
                                  class="font-italic"
                                >
                                  {{ $t('started') }} {{ formatDate(lesson.started_at) }}
                                  <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                                </h6>
                                <h6
                                  v-if="lesson.completed_at"
                                  class="font-italic text-success"
                                >
                                  <span v-if="lesson.user_id == self.id">
                                    <b>{{ $t('you') }}</b>
                                  </span>
                                  <span v-else>
                                    <b>{{ lesson.firstname }}</b>
                                  </span>
                                  {{ $t('completed') }} {{ formatDate(lesson.completed_at) }}
                                  <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                                </h6>
                              </div>

                              <!-- Actions -->
                              <b-col
                                class="d-flex
                              px-0
                              justify-content-between
                              align-items-baseline
                              flex-wrap action-btns"
                              >
                                <i
                                  v-if="lesson.is_unlock_request_sent"
                                  class="text-danger"
                                >
                                  {{ $t('student-lab-modul.unlock-request-sent') }}
                                </i>

                                <div>
                                  <template
                                    v-if="lesson.lesson_page && !lesson.hide_ebook"
                                  >
                                    <!-- Live Link -->
                                    <b-badge
                                      v-if="!hideEbook"
                                      pill
                                      variant="success"
                                      class=""
                                    >
                                      <span
                                        @click.stop="()=>{selectedLessonIdForEBook = lesson; selectedEventId=lesson.event_id}"
                                      >{{ $t("student-lab-module.e-book") }}
                                      </span>
                                    </b-badge>
                                    <b-badge
                                      v-if="lesson.live_link"
                                      pill
                                      variant="primary"
                                      class=""
                                      @click.stop
                                    >
                                      <a :href="lesson.live_link"
                                         target="_blank"
                                      >
                                        {{ $t('student-lab-module.page-link') }}
                                      </a>
                                    </b-badge>

                                    <!-- Essay -->
                                  </template>

                                  <!-- Notes -->
                                  <b-badge
                                    v-if="!hideNote"
                                    pill
                                    variant="warning"
                                    class=""
                                  >
                                    <a @click.stop="$emit('showNotes', lesson.lesson_id)">{{
                                      $t("student-lab-module.notes")
                                    }}</a>
                                  </b-badge>

                                  <!-- PDF -->
                                  <b-badge
                                    v-if="lesson.file && !hidePdf"
                                    pill
                                    variant="danger"
                                    class=""
                                  >
                                    <a @click.stop="openPdf(lesson.file)">
                                      {{ $t("labels.pdf") }}
                                    </a>
                                  </b-badge>
                                  <b-badge
                                    v-if="lesson.has_multi_practice && lesson.completed_at"
                                    pill
                                    variant="info"
                                    class=""
                                  >
                                    <a
                                      @click.stop="
                                        $router.push({
                                          name: `${self.usertype}-lesson`,
                                          params: { id: lesson.lesson_id },
                                          query: { type:'practice', event_id: lesson.event_id}
                                        })
                                      "
                                    >
                                      {{ $t("labels.practice_again") }}
                                    </a>
                                  </b-badge>
                                  <b-badge
                                    v-else-if="isAStudent && !hideMakeRequest"
                                    pill
                                    variant="primary"
                                    class=""
                                    @click.stop="showPracticeRequestModal(lesson.lesson_id)"
                                  >
                                    {{ $t('student-lab-module.make-request') }}
                                  </b-badge>
                                </div>
                              </b-col>

                            </b-col>
                          </b-row>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-tab>
                  <b-tab
                    v-show="followUPlesson(lessonGroups[0][group]).length > 0"
                  >
                    <template #title>
                      {{ $t('follow-up-lessons') }}
                      <b-badge variant="dark"
                               class="text-white badge-pill"
                      >
                        {{ followUPlesson(lessonGroups[0][group]).length }}
                      </b-badge>
                    </template>
                    <b-list-group
                      v-for="lesson,lIndex in followUPlesson(lessonGroups[0][group])"
                      :key="lesson.lesson_id+lIndex"
                    >
                      <b-list-group-item
                        v-if="!(lesson.is_locked && isAStudent && !isClassOwner)"
                        :key="index"
                        class="group mb-1"
                      >
                        <span
                          v-if="isATeacher"
                          style="position: absolute; right: 2px; top:-2px; z-index: 9;"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="text-warning"
                            @click="lessonIdToShowDetail = lesson.lesson_id"
                          />
                        </span>
                        <div
                          border-variant="secondary"
                          no-body
                          class="mb-0 mb-1"
                          @click="
                            () => {
                              if (lesson.is_unlock_request_sent) {
                                return
                              }
                              if (lesson.is_locked && isAStudent && !isClassOwner) {
                                showLockedMessage(lesson)
                                return
                              }
                              if(($route.query.student_id || lesson.completed_at) && hideResult) {
                                $router.push({
                                  name: `${self.usertype}-problems`,
                                  params: { ...$route.params,
                                    classId: classId || $route.params.classId,
                                    id: lesson.lesson_id
                                  },
                                  query: {
                                    event_id: lesson.event_id,
                                  },
                                });
                              } else if ($route.query.student_id || lesson.completed_at) {
                                $router.push({
                                  name: `${self.usertype}-course-result`,
                                  params: {
                                    classId: classId ||$route.params.classId,
                                    id: courseId,
                                    type: $route.params.type,
                                  },
                                  query: {
                                    lesson_id: lesson.lesson_id,
                                    student_id: $route.query.student_id,
                                  },
                                });
                              } else if (lesson.event_type === 'conversational') {
                                showLessonVisualizationModalForSpecificLesson(lesson)
                              } else {
                                if (selectedCourseId) {
                                  $emit('showLesson', lesson, 'follow_up')
                                } else {
                                  $router.push({
                                    name: `${self.usertype}-lesson`,
                                    params: { ...$route.params, id: lesson.lesson_id},
                                    query: {
                                      event_id: lesson.event_id,
                                      mode: 'follow_up'
                                    },
                                  });
                                }
                              }
                            }
                          "
                        >
                          <div v-if="lesson.event_type === 'conversational'"
                               v-b-tooltip.hover="'The is lesson is conversational mode'"
                               style="position: absolute; bottom: 0; right: 0; margin-right: 10px;"
                          >
                            <img
                              src="/conversational.png"
                              width="40px"
                              height="40px"
                            >
                          </div>
                          <!-- Completed Icon -->
                          <img
                            v-if="lesson.completed_at"
                            :src="require(`@/assets/images/${lessonCompletedBadge}`)"
                            class="lesson-completed-badge"
                          >
                          <feather-icon
                            v-else-if="lesson.is_locked && isAStudent && !isClassOwner"
                            icon="LockIcon"
                            class="lesson-completed-badge"
                          />
                          <!-- Lesson Details -->
                          <b-row class="align-items-start justify-content-left">
                            <b-col
                              class="pr-0 mb-md-0 col-3"
                            >
                              <!-- Lesson Image -->
                              <avatar
                                :image="lesson.image"
                                :rounded="true"
                                :default-icon="'book'"
                                class="lesson-image"
                              />
                            </b-col>

                            <!-- Details -->
                            <b-col
                              class="pl-1 detail-responsive col-9"
                            >
                              <!-- Lesson Details -->
                              <div class="lesson-details">
                                {{ group }}
                                <h4>{{ lesson.name }}</h4>

                                <h6
                                  v-if="lesson.start_time"
                                  class="font-italic"
                                >
                                  {{ $t('assigned-at') }}: {{ formatDay(lesson.start_time) }}
                                </h6>
                                <h6
                                  v-if="lesson.started_at"
                                  class="font-italic"
                                >
                                  {{ $t('started') }} {{ formatDate(lesson.started_at) }}
                                  <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                                </h6>
                                <h6
                                  v-if="lesson.completed_at"
                                  class="font-italic text-success"
                                >
                                  <span v-if="lesson.user_id == self.id">
                                    <b>{{ $t('you') }}</b>
                                  </span>
                                  <span v-else>
                                    <b>{{ lesson.firstname }}</b>
                                  </span>
                                  {{ $t('completed') }} {{ formatDate(lesson.completed_at) }}
                                  <AttemptCont :count="allLoadersHistory[lesson.event_id]?.[0].total" />
                                </h6>
                              </div>

                              <!-- Actions -->
                              <b-col
                                class="d-flex
                              px-0
                              justify-content-between
                              align-items-baseline
                              flex-wrap action-btns"
                              >
                                <i
                                  v-if="lesson.is_unlock_request_sent"
                                  class="text-danger"
                                >
                                  {{ $t('student-lab-modul.unlock-request-sent') }}
                                </i>

                                <div>
                                  <template
                                    v-if="lesson.lesson_page && !lesson.hide_ebook"
                                  >
                                    <!-- Live Link -->
                                    <b-badge
                                      v-if="!hideEbook"
                                      pill
                                      variant="success"
                                      class=""
                                    >
                                      <span
                                        @click.stop="()=>{selectedLessonIdForEBook = lesson; selectedEventId=lesson.event_id}"
                                      >{{ $t("student-lab-module.e-book") }}
                                      </span>
                                    </b-badge>
                                    <b-badge
                                      v-if="lesson.live_link"
                                      pill
                                      variant="primary"
                                      class=""
                                      @click.stop
                                    >
                                      <a :href="lesson.live_link"
                                         target="_blank"
                                      >
                                        {{ $t('student-lab-module.page-link') }}
                                      </a>
                                    </b-badge>

                                    <!-- Essay -->
                                  </template>

                                  <!-- Notes -->
                                  <b-badge
                                    v-if="!hideNote"
                                    pill
                                    variant="warning"
                                    class=""
                                  >
                                    <a @click.stop="$emit('showNotes', lesson.lesson_id)">{{
                                      $t("student-lab-module.notes")
                                    }}</a>
                                  </b-badge>

                                  <!-- PDF -->
                                  <b-badge
                                    v-if="lesson.file && !hidePdf"
                                    pill
                                    variant="danger"
                                    class=""
                                  >
                                    <a @click.stop="openPdf(lesson.file)">
                                      {{ $t("labels.pdf") }}
                                    </a>
                                  </b-badge>
                                  <b-badge
                                    v-if="lesson.has_multi_practice && lesson.completed_at"
                                    pill
                                    variant="info"
                                    class=""
                                  >
                                    <a
                                      @click.stop="
                                        $router.push({
                                          name: `${self.usertype}-lesson`,
                                          params: { id: lesson.lesson_id },
                                          query: { type:'practice', event_id: lesson.event_id}
                                        })
                                      "
                                    >
                                      {{ $t("labels.practice_again") }}
                                    </a>
                                  </b-badge>
                                  <b-badge
                                    v-else-if="isAStudent && !hideMakeRequest"
                                    pill
                                    variant="primary"
                                    class=""
                                    @click.stop="showPracticeRequestModal(lesson.lesson_id)"
                                  >
                                    {{ $t('student-lab-module.make-request') }}
                                  </b-badge>
                                </div>
                              </b-col>

                            </b-col>
                          </b-row>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-tab>
                </b-tabs>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <b-card v-else>
          {{ $t("labels.no-lessons-yet") }}
        </b-card>
      </b-col>
    </b-row>
    <lesson-detail-model
      :show="!!lessonIdToShowDetail"
      :lesson-id="lessonIdToShowDetail"
      @close="lessonIdToShowDetail=0"
    />
    <lesson-visualize-modal
      v-if="lessonToVisualize && lessonVisualizationInfo"
      :visualization-info="lessonVisualizationInfo"
      :lesson-group-name="lessonToVisualize"
      :class-id="classId"
      @reFetch="showLessonVisualizationModal(selectedLessonForVisualize)"
    />
    <practice-request-modal
      v-if="selectedLesson"
      :lesson-id="selectedLesson"
    />

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BBadge,
  BButton,
  VBTooltip,
  BProgress,
  BTabs, BTab,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import Avatar from '@/views/common/components/Avatar.vue'
import { LESSON_COMPLETED_BADGE } from '@/const/badges'
import { USER_TYPE_TEACHER, USER_TYPE_STUDENT } from '@/const/userType'
import PdfViewer from '@/views/common/components/PdfViewer.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import LessonDetailModel from '@/views/common/components/LessonDetailModel.vue'
import SkillTypes from '@/const/skillTypes'
import { LANG_LEVELS } from '@/const/langLevel'
import { engagementSettings, isVisible } from '@/utils/visibilitySettings'
import EbookSelector from './EbookSelector.vue'
import LessonVisualizeModal from './LessonVisualizeModal.vue'
import PracticeRequestModal from './PracticeRequestModal.vue'
import AttemptCont from './AttemptCont.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Avatar,
    BButton,
    BBadge,
    BProgress,
    AttemptCont,
    PdfViewer,
    EbookSelector,
    LessonDetailModel,
    LessonVisualizeModal,
    PracticeRequestModal,
    BTabs,
    BTab,
    BCollapse,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    lessonGroups: {
      type: Array,
      default: () => {},
    },
    activeGroup: {
      type: [String, Number],
      default: '',
    },
    classId: {
      type: Number,
      default: () => null,
    },
    classRoomInfo: {
      type: Object,
      default: () => {},
    },
    lessonProgressList: {
      type: Array,
      default: () => [],
    },
    allLoadersHistory: {
      type: Object,
      default: () => {},
    },
    selectedCourseType: {
      type: String,
      default: '',
    },
    selectedCourseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      lessonIdToShowDetail: 0,
      activePdf: null,
      showPdf: false,
      self: getUserData(),
      loaderLessons: [],
      completedLessons: [],
      lessonCompletedBadge: LESSON_COMPLETED_BADGE,
      selected: '',
      isProcessing: false,
      selectedEventId: null,
      selectedLesson: null,
      lessonRulesOptions: [
        {
          text: 'Skill',
          options: [
            ...SkillTypes.filter(item => !!item.value),
            {
              text: 'Oe Writing',
              value: 'oe-writing',
            },
            {
              text: 'Oe Speaking',
              value: 'oe-speaking',
            },

          ],
          key: 'skill_type',
          on_if_weakest_skill: false,
        },
        {
          text: 'Problem Type',
          key: 'problem_type',
          on_if_weakest_skill: true,
          options: [
            {
              text: 'Prompted',
              value: 'prompted',
            },
            {
              text: 'Open Ended',
              value: 'open-ended',
            },
          ],
        },
        {
          text: 'Level',
          options: [...LANG_LEVELS.map((item, index) => ({ text: item, value: index + 1 }))],
          key: 'level',
          always_on: true,
          ignoreEngine: 'adaptivePathEngine',
          on_if_weakest_skill: true,
        },
      ],
      defaultEngineOptions: [
        { text: 'Default', value: null },
        { text: 'DDF', value: 'ddf' },
        { text: 'CAF', value: 'caf' },
        { text: 'Linear', value: 'linearStandAloneEngine' },
        { text: 'Adaptive Path Engine', value: 'adaptivePathEngine' },
      ],
      selectedLessonIdForEBook: null,
      lessonToVisualize: null,
      lessonVisualizationInfo: null,
      hideNote: 1,
      hideEbook: 1,
      hidePdf: 1,
      hideMakeRequest: 1,
      selectedLessonForVisualize: null,
      lessonTypeTab: 0,
    }
  },
  computed: {
    selectedGroup() {
      return this.selected ? this.selected : this.activeGroup
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isClassOwner() {
      return Number(this.classRoomInfo?.owner?.id) === Number(this.self.id)
    },
    aboveStudent() {
      return isHighLevelUser(8)
    },
    isHighLevelUser() {
      return isHighLevelUser()
    },

    hideResult() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('lesson_result', schoolSetting, distSetting)
    },
    courseId() {
      return this.selectedCourseId ? this.selectedCourseId : this.$route.params.id
    },
    courseType() {
      return this.selectedCourseType ? this.selectedCourseType : this.$route.params.type
    },
  },
  created() {
    this.getClassRoomSettings()
  },
  mounted() {
    if (this.selectedCourseType === 'follow_up' || this.$route.query.type?.startsWith('follow_up')) {
      this.$nextTick(() => {
        this.lessonTypeTab = 1
      })
    }
  },
  methods: {
    showPracticeRequestModal(lessonId) {
      this.selectedLesson = lessonId
      this.$nextTick(() => {
        this.$bvModal.show('practice-request-modal')
      })
    },
    canShowWeakestSkill(lesson) {
      return lesson.default_engine === 'adaptivePathEngine' || lesson.default_engine === 'linearStandAloneEngine'
    },
    openCalendar() {
      this.$router.push({
        name: `${this.self.usertype}-class-calendar`,
        params: { id: this.classId, classId: this.classId },
      })
    },
    countStudentOccurrence(array) {
      if (!array) {
        return ''
      }
      // eslint-disable-next-line no-return-assign, no-plusplus, no-sequences
      const occurrences = array.split(',').reduce((acc, curr) => (acc[curr] ? ++acc[curr] : acc[curr] = 1, acc), {})
      let strings = '<div class="text-left"><b class="text-white">Lesson Completed By</b><br/>'
      Object.entries(occurrences).forEach(([key, value]) => {
        strings += `${key} x ${value}<br/>`
      })
      strings += '</div>'
      return strings
    },
    getAlgorithms() {
      useJwt.getLearningAlgorithms({
        parent: {
          is_public: 1,
        },
      }).then(res => {
        this.defaultEngineOptions = [...this.defaultEngineOptions, ...res.data.data.map(item => ({ text: item.name, value: item.name.toString() }))]
      }).finally(() => {
        // this.isProcessing = false
      })
    },
    orderLesson(lessons) {
      const allLessons = [...lessons]
      const sortLesson = allLessons.filter(lesson => lesson.event_type !== 'follow_up')
      sortLesson.sort((a, b) => a.lesson_order_index - b.lesson_order_index)
      // console.log(sortLesson)
      return sortLesson
    },
    followUPlesson(lessons) {
      const allLessons = [...lessons]
      const followUpLessons = allLessons.filter(lesson => lesson.event_type === 'follow_up')
      // console.log(followUpLessons, lessons)
      followUpLessons.sort((a, b) => a.lesson_order_index - b.lesson_order_index)
      return followUpLessons
    },
    selectGroup(group) {
      this.selected = group
      this.$emit('select-group', group)
    },
    navigateTo(rData) {
      const routeData = this.$router.resolve(rData)
      window.open(routeData.href, '_blank')
    },
    openPdf(file) {
      this.activePdf = file
      this.showPdf = true
    },
    findLoader(id) {
      if (this.loaderLessons) {
        return this.loaderLessons.find(element => element.lesson_id === id)
      }
      return false
    },
    // setOffLessons(index) {
    //   let toggle = ''
    //   this.lessonGroups[0][this.selectedGroup][index].is_engine_on = !this.lessonGroups[0][this.selectedGroup][index].is_engine_on * 1
    //   toggle = this.lessonGroups[0][this.selectedGroup][index].is_engine_on
    //     ? 'on'
    //     : 'off'

    //   const params = {
    //     class_id: this.classId,
    //     course_id: parseInt(this.$route.params.id, 10),
    //     lesson_id: parseInt(
    //       this.lessonGroups[0][this.selectedGroup][index].lesson_id,
    //       10,
    //     ),
    //     _method: 'PUT',
    //   }

    //   useJwt.setOffLessons(toggle, params).then(res => {
    //     this.showSuccessMessage(res)
    //   })
    // },
    formatDay(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatDate(date) {
      return moment
        .utc(date)
        .local()
        .fromNow()
    },
    canRequestNewLesson() {
      if (this.selectedGroup && this.lessonGroups[0][this.selectedGroup]) {
        const hasIncompleteLesson = this.lessonGroups[0][this.selectedGroup].find(
          lesson => !lesson.is_locked && !lesson.completed_at,
        )
        return !hasIncompleteLesson
      }
      return false
    },
    requestLessonUnlock(lesson) {
      const lessonId = lesson.lesson_id
      const canRequestNewLesson = this.canRequestNewLesson()
      if (!canRequestNewLesson) {
        this.$swal({
          title: 'Please complete all lessons',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          buttonsStyling: false,
        })
        return null
      }
      return useJwt.requestLockedLessonToUnlock(this.classId, lessonId).then(response => {
        // eslint-disable-next-line no-param-reassign
        lesson.is_unlock_request_sent = true
        if (response.data?.data?.request_exists) {
          this.$swal({
            title: 'Lesson request already Sent',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            showClass: {
              popup: 'animate__animated animate__shakeX',
            },
            buttonsStyling: false,
          })
          return
        }
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    showLockedMessage(lesson) {
      // const canRequestNewLesson = this.canRequestNewLesson()
      const self = this
      this.$swal({
        title: 'You are not assigned this Lesson yet.',
        text: 'Please request your teacher to unlock it.',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        confirmButtonText: 'Request Now',
        showCancelButton: true,
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return self.requestLessonUnlock(lesson)
        },
      })
    },
    setDefaultEngine(index) {
      const params = {
        _method: 'PUT',
        class_id: this.classId,
        course_id: Number(this.courseId),
        lesson_id: Number(this.lessonGroups[0][this.selectedGroup][index].lesson_id),
        default_engine: this.lessonGroups[0][this.selectedGroup][index].default_engine,
      }
      const engineWithRules = ['adaptivePathEngine', 'linearStandAloneEngine']
      useJwt.updateClassroomLessonEngine(params).then(res => {
        this.showSuccessMessage(res)
        const lesson = this.lessonGroups[0][this.selectedGroup][index]
        if (lesson.default_engine === engineWithRules[0]) {
          // eslint-disable-next-line no-param-reassign
          lesson.rules.level = 'all'
        } else if (lesson.default_engine === engineWithRules[1] && lesson.rules.level === 'all') {
          // eslint-disable-next-line no-param-reassign
          lesson.rules.level = 1
        }
        if (engineWithRules.includes(params.default_engine)) {
          this.changeRule(lesson)
        }
      }).catch(error => this.showErrorMessage(error))
    },
    changeRule(lesson) {
      useJwt.updateLessonRule({
        classroom_id: this.classId,
        course_id: Number(this.courseId),
        lesson_id: lesson.lesson_id,
        has_multi_practice: lesson.has_multi_practice,
        rules: lesson.rules,
      }).then(res => {
        this.showSuccessMessage(res)
        if (this.canShowWeakestSkill(lesson)) {
          if (lesson.rules.level === 'all') {
          // eslint-disable-next-line no-param-reassign
            lesson.default_engine = 'adaptivePathEngine'
          // this.setDefaultEngine(index)
          } else if (lesson.rules.level !== 'all') {
          // eslint-disable-next-line no-param-reassign
            lesson.default_engine = 'linearStandAloneEngine'
          }
        }
      }).finally(() => {
        // this.isProcessing = false
      })
    },

    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const data = res.data?.data
        const districtSettings = res.data?.district_settings

        // check for district and school level settings
        // note
        const schoolNote = +data?.find(item => item.setting_name === 'note')?.value || 0
        const districtNote = +res.data?.district_settings.find(item => item.setting_name === 'note')?.value || 0
        this.hideNote = districtNote === 1 || schoolNote === 1 ? 1 : 0

        // ebook
        const schoolEbook = +data?.find(item => item.setting_name === 'ebook')?.value || 0
        const districtEbook = +districtSettings.find(item => item.setting_name === 'ebook')?.value || 0
        this.hideEbook = districtEbook === 1 || schoolEbook === 1 ? 1 : 0

        // pdf
        const schoolPdf = +data?.find(item => item.setting_name === 'pdf')?.value || 0
        const districtPdf = +districtSettings.find(item => item.setting_name === 'pdf')?.value || 0
        this.hidePdf = districtPdf === 1 || schoolPdf === 1 ? 1 : 0

        // make request
        const schoolMakeRequest = +data?.find(item => item.setting_name === 'make_request')?.value || 0
        const districtMakeRequest = +districtSettings.find(item => item.setting_name === 'make_request')?.value || 0
        this.hideMakeRequest = districtMakeRequest === 1 || schoolMakeRequest === 1 ? 1 : 0

        const fancyAudioPlayer = engagementSettings('fancy_audio_player', data, districtSettings)
        // remove 'fancy_audio_player' from settings and append new setting
        let settings = data.filter(setting => setting.setting_name !== 'fancy_audio_player')
        settings.push(fancyAudioPlayer)

        // engagement settings
        const engagementKeys = ['game', 'avatar', 'attention']
        const engagementSetting = engagementSettings(engagementKeys, data, districtSettings)
        settings = data.filter(setting => !engagementKeys.includes(setting.setting_name))
        settings.push(...engagementSetting)

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
    showLessonVisualizationModalForSpecificLesson(lessons) {
      // console.log(lessons)
      this.selectedLessonForVisualize = lessons
      this.lessonToVisualize = lessons.lesson_group_name
      const params = {
        lesson_group_id: lessons.lesson_group_id,
        class_id: this.classId,
        // lesson_id: lessons.lesson_id,
      }
      useJwt.getLessonInfoForVisualization({ params }).then(response => {
        this.lessonVisualizationInfo = response.data.data
        this.lessonVisualizationInfo.selectedLesson = lessons.lesson_id
        this.$nextTick(() => { this.$bvModal.show('lesson-visualize-modal') })
      })
    },
    showLessonVisualizationModal(lessons) {
      this.selectedLessonForVisualize = lessons
      this.lessonToVisualize = lessons[0]?.lesson_group_name
      const params = {
        lesson_group_id: lessons[0]?.lesson_group_id,
        class_id: this.classId,
      }
      useJwt.getLessonInfoForVisualization({ params }).then(response => {
        this.lessonVisualizationInfo = response.data.data
        this.$nextTick(() => { this.$bvModal.show('lesson-visualize-modal') })
      })
    },
  },
}
</script>

<style scoped>
.group {
  cursor: pointer;
}
.selected {
  background-color: #d9d9d9;
}
.dark-layout .selected {
  background-color: #414e71;
}
.lesson-completed-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}
.ddf-switch {
  padding: 0 8px;
}
.lesson-details {
  min-height: 45px;
}
.border-secondary{
  border: 1px solid #ddd !important
}
.lesson-image {
  min-width: 100px;
  max-width: 100px;
  height: 100px !important;
}
.lesson-group-mobile {
  display: none;
}
@media(max-width:1300px) and (min-width:1200px) {
 .lesson-details{
  padding-left: 10px;
 }
}
@media(max-width:1200px) and (min-width:940px) {
 .detail-responsive{
  padding-left: 45px !important
 }
}
@media(max-width:940px) and (min-width:840px) {
  .detail-responsive{
    padding-left: 50px !important;
  }
}
@media(max-width:840px) and (min-width:768px) {
  .lesson-completed-badge {
    width: 25px;
    height: 25px;
  }
  .lesson-image {
    min-width: 80px !important;
    max-width: 80px !important;
  }
  .detail-responsive{
    padding-left: 50px !important;
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .detail-responsive .align-items-baseline span.badge{
    margin-left:0px !important;
    font-size: 11px;
  }
}
@media(max-width:1100px) and (min-width:768px) {
 .lesson-image {
  min-width: 90px;
  max-width: 90px;
  height: 90px !important;
  }
}
@media(max-width:768px) {

  .lesson-image {
    min-width: 100% !important;
    max-width: 100% !important;
    height: unset !important;
    text-align:center;
  }
  .lesson-completed-badge {
    z-index:2
  }
  .lesson-group-large {
    display: none;
  }
  .lesson-group-mobile {
    display: block;
  }
  .lesson-group-mobile .lesson-completed-badge {
    width: 22px;
    height: 22px;
  }
  .lesson-group-mobile .lesson-details h4 {
    font-size: 14px;
    margin-bottom: 4px
  }
  .lesson-group-mobile .lesson-details h6 {
    font-size: 12px;
    margin-bottom: 4px
  }
  .lesson-group-mobile .progress {
    margin-top:5px;
  }
  .lesson-group-mobile .lesson-group-collapse{
    margin-top: 10px;
    padding-top: 10px;
  }
  .lesson-group-mobile .action-btns .badge{
    margin:0px 4px 4px 0px;
  }
  .lesson-group-mobile .lesson-group-tab .nav-item {
    background-color: red;
  }
}
</style>
<style>
.lesson-group-mobile .lesson-group-tab .nav-link {
    padding:5px;
  }
  .lesson-group-mobile .lesson-group-tab .nav-link .badge{
    margin-left: 6px;
  }
</style>
